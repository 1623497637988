import { useEffect, useState } from "react";
import Title from "../../components/Title/Title";
import "./Vacancies.scss";
import CardEngineer from "../../components/CardEngineer";

import allDataEngineers from "./dataEngineer/dataEngineer";
import { getPosts } from "../../axios/pages";
import ApiForm from "../../components/ApiForm/ApiForm";
const { dataEngineers } = allDataEngineers;


export const Vacancies = () => {
  const [openId, setId] = useState(0);
  const [vacanciesList, setVacanciesList] = useState(false);

  const clickHandler = (id) => {
    if (id === openId) {
      setId(openId);
    } else {
      setId(id);
    }
  };

  useEffect(()=>{
    let active = false;
    getPosts('vacancies').then(response => {
        const data = response['hydra:member'];
        const activeVac = data.filter(vacancie => vacancie.active); // показати тільки активні вакансії

        // if (active) {
            setVacanciesList(activeVac);
        // }
    }).catch(err=>{
            console.log(err);
    })
      // return () => {
      //     active = true;
      // }; 
  }, []);

  return (
    <>
      <div className="container">
        <section className="vacancies">
          <Title
            num1={"01"}
            num2={"02"}
            row1={"нам потрібні"}
            row2={" хороші люди"}
          />

          <div className="vacancies__wrapper acord">
            <ul className="acord__list">
              {/* ПЕРЕБІР ДЛЯ СПИСКУ РАЗОМ З ВІДПОВІДЯМИ ДЛЯ ДЕСКТОПУ */}
              <div className="acord__wrapper">
                {vacanciesList && vacanciesList.map((item, id) => (
                  <>
                    <li
                      className={`acord__item ${id === openId ? "active" : ""}`}
                      key={id}
                      id={id}
                      onClick={() => clickHandler(id)}
                    >
                      <div className="acord__item--number">{id < 10 ? `0${id + 1}` : id + 1}</div>
                      <div
                        className={`acord__item--text ${
                          id === openId ? "active" : ""
                        }`}
                      >
                        {item.name}
                      </div>
                    </li>
                    <div
                      className={`vacancies__content vacancies__content--phone vac acord__content ${
                        id === openId ? "active" : ""
                      }`}
                      dangerouslySetInnerHTML={{__html: item.content}}
                    >
                    </div>
                  </>
                ))}
                <div className="resume-inner">
                  <ApiForm id={3} showTitle = {false}  showLabels={false} sendBtn = {'Відправити резюме'}/>
                </div>
              </div>

              {/* ПЕРЕБІР ДЛЯ СПИСКУ РАЗОМ З ВІДПОВІДЯМИ ДЛЯ ТЕЛЕФОНУ */}
              {/* <div className="acord__wrapper"> */}
                {vacanciesList && vacanciesList.map((item, id) => (
                  <>
                    <div
                      className={`vacancies__content vacancies__content--desktop vac acord__content ${
                        id === openId ? "active" : ""
                      }`}
                      id={id}
                      key={id}
                      dangerouslySetInnerHTML={{__html: item.content}}
                    >
                    </div>
                  </>
                ))}
              {/* </div> */}
            </ul>
          </div>
          <Title
            num1={"02"}
            num2={"02"}
            row1={"робота"}
            row2={" з персоналом"}
          />

          <ul className="list_CardEngineer">
            {dataEngineers.map((el, i) => (
              <CardEngineer objEngineer={el} key={i} />
            ))}
          </ul>
        </section>
      </div>
    </>
  );
};
