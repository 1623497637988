import React from 'react';
import * as DOMPurify from 'dompurify';
import "./historymodal.scss";

const HistoryModal = ({ id, show, currentIndex }) => {
  const showElements =
    [
      {
        id: 1954,
        title: 'Козівський підрозділ',
        content: `
      <p>Будівництво цукрового заводу  розпочато в 1954 році, а першу продукцію завод випустив 29 грудня 1959 року.<br/>Виробнича потужність заводу – 3700 тис. тонн/добу переробки цукрового буряка. <br/>Завод виробляє цукор, мелясу та жом сухий гранульований.
      <p/>`
      },
      {
        id: 1958,
        title: 'Хоростківський підрозділ',
        content: `
        <p>В 1958 році закінчено комплексне випробування устаткування першої черги заводу на виробничу потужність 1500 тонн переробки цукрових буряків за добу. 16 грудня 1958 року з виробництва було отримано перший цукор. Надалі удосконалювались технологічні та теплові схеми, проводилася автоматизація виробничих процесів.
        <p/>`
      },
      {
        id: 1958,
        title: 'Гнідавський підрозділ',
        content: `
        <p>У 1958 році закінчено комплексне випробування устаткування першої черги заводу на виробничу потужність 1,5 тис. т переробки буряків на добу. 
        У грудні 1958 року було одержано перший цукор. <br/>Виробнича потужність заводу – 6 тис. тонн/добу переробки цукрового буряка.<br/>Завод виробляє цукор, мелясу та жом сухий гранульований.
        <p/>`
      },
      {
        id: 1963,
        title: 'Збаразький підрозділ',
        content: `
        <p>Будівництво Збаразького цукрового заводу було розпочате в листопаді і введено в експлуатацію в 1969 році.<br/>
            Виробнича потужність заводу –  3700тис. тонн/добу переробки цукрового буряка.<br/>
            Завод виробляє цукор та мелясу.
        <p/>`
      },
      {
        id: 1963,
        title: 'Хоростківський підрозділ',
        content: `
        <p>В 1958 році закінчено комплексне випробування устаткування першої черги заводу на виробничу потужність 1500 тонн переробки цукрових буряків за добу. 16 грудня 1958 року з виробництва було отримано перший цукор. Надалі удосконалювались технологічні та теплові схеми, проводилася автоматизація виробничих процесів.
        <p/>`
      },
      {
        id: 1974,
        title: 'Радехівський підрозділ',
        content: `
    <p>Будівництво Радехівського цукрового заводу розпочалося
    в червні  1974 року на території с. Павлів Радехівського району Львівської області та ривало 4 роки. На той час це був  п’ятий Львівський цукровий завод. 
    Технічний проект будівництва заводу потужністю 6 тис. тонн/добу по переробці цукрових буряків було розроблено в 1974 році Укргіпроцукром.
    <p/>
    <p>10 грудня 1978 року завод був зданий в експлуатацію.
    Завод забезпечений під'їзними шляхами як залізничного, так і автомобільного транспорту. Сировинна зона розміщується в Радехівському, 
    Сокальському, Буському, Кам'янка-Бузькому, Бродівському, Жовківському районах Львівської області, а також в Горохівському, Локачинському районах 
    Волинської області та деяких районах Рівненської області.
    <p/>
    <p>За роки діяльності заводу було проведено повне технічне переоснащення та модернізацію технологічних процесів виробництва продукції.<p/>
    <div class='data'>
        <div class='data__element'>
        <p><span>159</span> га</p>
        <svg xmlns="http://www.w3.org/2000/svg" width="2" height="50" viewBox="0 0 2 50" fill="none">
            <path d="M1 1L0.999998 49" stroke="#676D73" strokeLinecap="round"/>
        </svg>
        <p>Загальна площа території заводу </p>
        </div>
        <div class='data__element'>
        <p><span>8 000</span> тонн/добу</p>
        <svg xmlns="http://www.w3.org/2000/svg" width="2" height="50" viewBox="0 0 2 50" fill="none">
            <path d="M1 1L0.999998 49" stroke="#676D73" strokeLinecap="round"/>
        </svg>
        <p>Виробнича потужність заводу переробки цукрового буряка</p>
        </div>
        <div class='data__element'>
        <p>Товарний цукор-пісок</p>
        <svg xmlns="http://www.w3.org/2000/svg" width="2" height="50" viewBox="0 0 2 50" fill="none">
            <path d="M1 1L0.999998 49" stroke="#676D73" strokeLinecap="round"/>
        </svg>
        <p>Основна продукція </p>
        </div>
        <div class='data__element'>
        <p>Меляса Жом сирий сухий гранульований</p>
        <svg xmlns="http://www.w3.org/2000/svg" width="2" height="50" viewBox="0 0 2 50" fill="none">
            <path d="M1 1L0.999998 49" stroke="#676D73" strokeLinecap="round"/>
        </svg>
        <p>Побічна продукція </p>
        </div>
    </div>`,
      },
      {
        id: 1977,
        title: 'Чортківський підрозділ',
        content: `
        <p>Завод був введений в експлуатацію в листопаді 1977 року.<p/>
        <p>В травні 2013 року ТзОВ «Радехівський цукор» розширило межі своєї діяльності на територію Тернопільської області шляхом придбання виробничих потужностей ТзОВ «Чортківський цукровий завод».<p/>
        <p>Виробнича потужність заводу – 7 тис. тонн/добу переробки цукрового буряка.
        Завод виробляє цукор, мелясу та жом сухий гранульований.<p/>`
      },
    ]

  const modal = showElements.filter(item => {
    return item.id === id;
  })

  return (
    modal &&
    <div className="modal">
      <h3>{modal[currentIndex].title}
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none" onClick={() => { show(false) }}>
          <path d="M1 1L25 25" stroke="#C2C7CC" strokeLinecap="round" />
          <path d="M25 1L1.00003 25" stroke="#C2C7CC" strokeLinecap="round" />
        </svg>
      </h3>
      <div className="modal__inner" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(modal[currentIndex].content) }}></div>
    </div>
  )
}

export default HistoryModal