import { AxiosInstance } from "./axios";

export const getLocationList = async () =>{
    const response = await AxiosInstance.get(`/locations?pagination=false`);
    return response.data;
}

export const getSpecialtiesList = async (id) =>{
    const response = await AxiosInstance.get(`/specialties/${id}`);
    return response.data;
}

export const getSpecialityInfo = async (id)=>{
    try {
        const specialty = await getSpecialtiesList(id);
        return specialty;
    }
    catch(error) {
      console.log(error)
    }
}

export const getUserInfo= async (id) =>{
    const response = await AxiosInstance.get(`/users/${id}`);
    return response.data;
}

export const getSubscriberInfo= async (param, event) =>{
    const response = await AxiosInstance.get(`/subscribers`, {
        params: {
            "event.id": event,
            ...param
        }
    });
    return response.data;
}

export const getEventId = async (id='') =>{
    const response = (id != '') ? await AxiosInstance.get(`/events/${id}`) : await AxiosInstance.get(`/events`);
    return response.data;
}

export const getEventLocations = async (id) =>{
    const response = await AxiosInstance.get(`/event_locations?event.id=${id}`);
    return response.data;
}


export const getMaterialsList = async (id) =>{
    const response = await AxiosInstance.get(`/materials?events.id=${id}`);
    return response.data;
}