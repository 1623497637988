import React, { Fragment } from 'react'
import EnergyDynamicBlock from './DynamicBlocks/EnergyDynamicBlock'
import EnergyDynamicList from './DynamicBlocks/EnergyDynamicList'
import { sortObj } from '../Store/sortObj'

const DynamicInfos = ({pageData, startIndex}) => {
    
  return (
    <>
      {pageData?.length > 0 && 
          sortObj(pageData, 'sort').map((data, index) =>{
            const currentIndex = index + startIndex;
              switch (data.key) {
              case "contract_buttons":
                  return <EnergyDynamicBlock data= {data.data} index = {currentIndex} key = {data.id} />
              case "contract_links":
                  return <EnergyDynamicList data= {data.data} index = {currentIndex} key = {data.id} />
              default:
                  //statements_def
                 return <Fragment key={data.id}></Fragment>;
              }
      })}
    </>
  )
}

export default DynamicInfos