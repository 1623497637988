import React, { useEffect, useMemo, useState } from "react";
import css from "./report.module.scss";
import { getPosts } from "../../axios/pages";
import DynamicInfos from "../../components/DynamicInfos/DynamicInfos";
// import { useTranslation } from "react-multi-lang";
// import { Link } from "react-router-dom";

export const Report = () => {
    // const translate = useTranslation();
    const [currentIndexFilter, setCurrentIndexFilter] = useState(0);
    const [selectedYear, setSelectedYear] = useState(null);
    const [pageData, setPageData] = useState([]);
    const [reportList, setReports] = useState(false);
    const [yaerList, setYearList] = useState([]);

    const changeActiveFilter = (index, year) => {
        setCurrentIndexFilter(index);
        setSelectedYear(year);
    };
  
    useEffect(()=>{
        getPosts('reports').then(response => {
            const data = response['hydra:member'];
            const activeReports = data.filter(report => report.active);
            const yearList = activeReports.map(report => {
                return new Date(report.dateEntered).getFullYear();
            }) // сформувати масів дат

            const sortedList = [...new Set(yearList)].sort((a, b) => b - a) // сортування унікальних дат 
            setYearList(sortedList);
            setSelectedYear(sortedList[0]);
            setReports(activeReports);

        }).catch(err=>{
                console.log(err);
        })
    }, []);

    useMemo(()=>{
        const currentData = reportList && reportList.find(report => {
            return new Date(report.dateEntered).getFullYear() === selectedYear;
        })
        if (currentData !== undefined) {
            setPageData(currentData.pageInfos);
        }
    }, [reportList, selectedYear])

    return (
        <section className={css["report"]}>
        <div className="container">
            {/* Фільтр по роках */}
            <div className={css["filterWrapper"]}>
                <ul>
                {yaerList.map((item, index) => (
                    <li key={index}>
                            <button
                                className={
                                index === currentIndexFilter ? css["active__button"] : ""
                                }
                                onClick={() => changeActiveFilter(index, item)}
                            >
                                {item}
                            </button>
                        </li>
                ))}
                </ul>
            </div>
            <DynamicInfos pageData={pageData} startIndex = {0}/>
        </div>
        </section>
    );
  }